const getToken = () => {
  let accountLs = localStorage.getItem('meainaAccount')
  if (accountLs) {
    let account = JSON.parse(accountLs)
    return account.token
  } else {
    return false
  }
}
const getAccount = () => {
  let accountLs = localStorage.getItem('meainaAccount')
  if (accountLs) {
    let account = JSON.parse(accountLs)
    return account.user
  } else {
    return {}
  }
}

const loginStatus = () => {
  let accountLs = localStorage.getItem('meainaLogin')
  if (accountLs) {
    return true
  } else {
    return false
  }
}

export default {
  getToken,
  getAccount,
  loginStatus
}