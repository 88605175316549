import { Link } from 'react-router-dom';
export default function Breadcumb({...props}) {
  function renderbreadcumb(){
    return props.links.map((item, index) => {
      return (
        <li key={index}>
          <Link to={item.path}>{item.title}</Link>
          {
            index < props.links.length - 1 && (
            <img src="/images/arrow-right.svg" alt="arrow"/>
            )
          }
        </li>
      )
    })
  }
  return (
    <div className="mina-breadcumb">
      <div className="mina-breadcumb-content d-flex align-items-center justify-content-start">
        <h1>{props.title}</h1>
        <ul className='d-flex align-items-center'>
          <li>
            <Link to="/">
              <img src="/images/home.svg" alt="home"/>
            </Link>
            <img src="/images/arrow-right.svg" alt="arrow"/>
          </li>
          {renderbreadcumb()}
        </ul>
      </div>
    </div>
  )
}