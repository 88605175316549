import { Link } from 'react-router-dom';
export default function FileSharingSidebar(){
  const category = ['Dokumen','Dokumen General','Dokumen Spesifik','Weekly Meeting','New Project']
  const filetype = [
    {
      label: 'PDF',
      icon: 'pdf.svg'
    },
    {
      label: 'Document',
      icon: 'doc.svg'
    },
    {
      label: 'Archive',
      icon: 'archive.svg'
    },
    {
      label: 'Audio',
      icon: 'audio.svg'
    },
    {
      label: 'Illustrator',
      icon: 'illustrator.svg'
    },
    {
      label: 'Persentation',
      icon: 'ppt.svg'
    },
    {
      label: 'Photoshop',
      icon: 'psd.svg'
    },
    {
      label: 'Video',
      icon: 'video.svg'
    },
    {
      label: 'sheet',
      icon: 'xls.svg'
    }
  ]
  return (
    <div className="mina-menu-left-filesharing mt-5 pt-3">
      <h6>Kategori</h6>
      <ul className="mina-menu-list">
        {category.map((item, index) => {
          return (
            <li key={index}>
              <Link to="#">{item}</Link>
            </li>
          )
        })}
      </ul>
      <h6>Tipe File</h6>
      <ul className="mina-menu-list">
        {filetype.map((item, index) => {
          return (
            <li key={index}>
              <Link to="#" className='d-flex align-items-center'>
                <img src={`/images/files/${item.icon}`} className="me-2" alt="icon"/>
                {item.label}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}