import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
export default function Table({children, ...props}) {
  const {onTableChange, heading, body, paging, action, onUpdate, onDelete} = props;
  const [tableHeading, setTableHeading] = useState(heading);
  const [tableBody, setTableBody] = useState(body);
  const [tablePaging, setTablePaging] = useState(paging);
  const handleSort = (key,sort) => {
    if(sort){
      let paging = {...tablePaging}
      paging.sortBy = key;
      paging.sortDesc = !paging.sortDesc
      setTablePaging(paging)
      onTableChange(paging)
    }
  }
  const handlePage = (currentPage) => {
    if(currentPage > 0 && currentPage <= paging.totalPages) {
      let thePaging = {...paging}
      thePaging.currentPage = currentPage;
      setTablePaging(thePaging)
      onTableChange(thePaging)
    }
  }
  const handlePerPage = (perPage) => {
    let thePaging = {...paging}
    thePaging.perPage = perPage
    thePaging.currentPage = 1
    setTablePaging(thePaging)
    onTableChange(thePaging)
  }
  const handleSearch = (search) => {
    let thePaging = {...paging}
    thePaging.search = search
    setTablePaging(thePaging)
    onTableChange(thePaging)
  }
  const renderHeading = (heading) => {
    return heading.map((item, index) => {
      return (
        <th key={index} className={`text-center cursor-pointer`} width={item.width} onClick={() => {handleSort(item.key,item.sort)}}>
          {item.label}
          {
            (item.sort) ?
              (item.key == tablePaging.sortBy) ?
                (!tablePaging.sortDesc) ?
                (<i className="bi bi-caret-up-fill"></i>) 
                : (<i className="bi bi-caret-down-fill"></i>)
              : null
            : null
          }
        </th>
      )
    })
  }
  const renderBody = (body) => {
    if(body.length === 0) {
      return (
        <tr>
          <td colSpan={tableHeading.length} className={`text-center py-3`}>No data found</td>
        </tr>
      )
    } else {
      return body.map((item, idx) => {
        return (
          <tr key={idx}>
            {
              tableHeading.map((itemHd, index) => {
                if(itemHd.key === 'action') {
                  return (
                    <td key={index} className={`text-center`}>{renderAction((action) ? action : [], item)}</td>
                  )
                } 
                else if (itemHd.label === 'No') {
                  return (
                    <td key={index} className={`text-center`}>{paging.perPage * (paging.currentPage-1) + idx+1}</td>
                  )
                }
                else {
                  return (
                    <td key={index} className={`text-center`}>{item[itemHd.key]}</td>
                  )
                }
              })
            }
          </tr>
        )
      })
    }
  }
  const renderPaging = () => {
    let {currentPage, perPage, sortBy, sortDesc, search, totalRows, totalPages} = paging
    let thePaging = []
    if(currentPage > 4) {
      thePaging.push(<li key={0} className={`page-item`}><a className={`page-link`} onClick={() => {handlePage(1)}}>1</a></li>)
      thePaging.push(<li key={1} className={`page-item`}><span className={`page-link`}>...</span></li>)
    }
    for(let i=1; i<=totalPages; i++) {
      if((i <= currentPage + 3) && (i >= currentPage - 3)) {
        thePaging.push(<li key={i+1} className={`page-item ${(i==currentPage) && 'active'}`}><a className={`page-link`} onClick={() => {handlePage(i)}}>{i}</a></li>)
      }
    }
    if(currentPage < totalPages - 3) {
      thePaging.push(<li key={totalPages+1} className={`page-item`}><span className={`page-link`}>...</span></li>)
      thePaging.push(<li key={totalPages+2} className={`page-item`}><a className={`page-link`} onClick={() => {handlePage(totalPages)}}>{totalPages}</a></li>)
    }
    return thePaging 
  }
  const renderAction = (action, data) => {
    return action.map((item, index) => {
      switch (item) {
        case 'update':
          return (<button key={index} className='btn btn-info btn-sm mx-1 text-light' onClick={()=>{ onUpdate(data) }}><i className="bi bi-pencil"></i></button>)
          break;
        case 'delete':
          return (<button key={index} className='btn btn-danger btn-sm mx-1 text-light' onClick={()=>{ onDelete(data) }}><i className="bi bi-trash"></i></button>)
          break;
        case 'create-sub':
          return (<Link to='/administration/settings/menuitems' key={index} className='btn btn-success btn-sm mx-1 text-light' onClick={()=>{}}><i className="bi bi-plus"></i></Link>)
          break;
      }
    })
  }
  useEffect(() => {
    
  },[])
  return (
    <>
    <div className='mina-table-navbar p-3 my-4 d-flex justify-content-between'>
      <div className='row g-2 align-items-center'>
        <div className='col-auto'>
          <label className='col-form-label'>Show</label>
        </div>
        <div className='col-auto'>
          <select className='form-control' value={tablePaging.perPage} onChange={(e)=>{handlePerPage(e.target.value)}}>
            {
              (paging.perPageOptions) ?
              paging.perPageOptions.map((item, index) => {
                return (
                  <option key={index} value={item}>{item}</option>
                )
              }) : null
            }
          </select>
        </div>
        <div className='col-auto'>
          <span className='form-text'>Entries</span>
        </div>
      </div>
      <div className='row g-2 align-items-center'>
        <div className='col-auto'>
          <input className='form-control' type='text' placeholder='Search...' value={tablePaging.search} onChange={(e)=>{handleSearch(e.target.value)}}/>
        </div>
      </div>
    </div>
    <div className="my-3 w-100">
      <div className="table-responsive">
        <table className="table table-bordered table-hover table-sm mina-table table-striped">
          <thead>
            <tr>
              {renderHeading(tableHeading)}
            </tr>
          </thead>
          <tbody>
            {renderBody(body)}
          </tbody>
        </table>
        <div className='d-flex justify-content-between align-items-center mina-table-paging'>
          <div className='mtp-info'>
            Showing {paging.perPage * (paging.currentPage-1) + 1} to {(paging.perPage * (paging.currentPage-1) + paging.perPage > paging.totalRows) ? paging.totalRows : paging.perPage * (paging.currentPage-1) + paging.perPage} of {paging.totalRows} entries
          </div>
          <nav className='mina-paging'>
            <ul className="pagination m-0 p-0">
              <li className="page-item page-item-prev"><a className="page-link" onClick={() => {handlePage(paging.currentPage - 1)}}><i className="bi bi-chevron-left"></i></a></li>
              {renderPaging()}
              <li className="page-item page-item-next"><a className="page-link" onClick={() => {handlePage(paging.currentPage + 1)}}><i className="bi bi-chevron-right"></i></a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    </>
  )
}