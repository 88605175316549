import {Toast, ToastContainer} from 'react-bootstrap'
import axios from '../../config/axios';
import {useState, useEffect} from 'react'
import menu from '../../config/menu'
import { Link } from 'react-router-dom';
import userStore from '../../stores/userStore';
export default function Header() {
  const [showAccountForm, setShowAccountForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const [loginState, setLoginState] = useState(false);
  const [token, setToken] = useState('');
  const [account, setAccount] = useState('');
  const [info, setInfo] = useState({
    title: '',
    msg: '',
    bg: 'Primary'
  });
  const renderMenu = () =>{
    return menu.map((item, index) => {
      if(!item.auth){
        return (
          <li key={index}>
            <Link to={item.path}>{item.name}</Link>
          </li>
        )
      }
    })
  }
  const renderMenuAuth = () =>{
    return menu.map((item, index) => {
      return (
        <li key={index}>
          <Link to={item.path}>{item.name}</Link>
        </li>
      )
    })
  }
  const toggleMenu = () => {
    setShowAccountForm(!showAccountForm);
  }
  const doLogin = async () => {
    try {
      let url = '/auth/login'
      let payload = new FormData()
      payload.append('username', username)
      payload.append('password', password)
      let res = await axios.post(url, payload)
      if(res.status == 200){
        // console.log(res.data);
        let data = JSON.stringify(res.data)
        localStorage.setItem('meainaLogin', true);
        localStorage.setItem('meainaAccount', data)
        setLoginState(true);
        setShowInfo(true);
        setInfo({
          title: 'Success',
          msg: 'Login berhasil',
          bg: 'success'
        })
        window.location.reload()
        toggleMenu();
        setPassword('');
        setUsername('');
      }
    } catch (error) {
      setShowInfo(true);
      setInfo({
        title: 'Error',
        msg: 'Login failed, internal server error',
        bg: 'danger'
      })
      toggleMenu();
      setPassword('');
      setUsername('');
    }
  }
  const doLogout = () =>{
    window.location.replace('/');
    localStorage.removeItem('meainaLogin');
    setPassword('');
    setUsername('');
    setLoginState(false);
    setShowInfo(true);
    setInfo({
      title: 'Success',
      msg: 'Logout berhasil',
      bg: 'success'
    })
    toggleMenu();
  }
  useEffect(() => {
    setLoginState(userStore.loginStatus);
    setAccount(userStore.getAccount);
    setToken(userStore.getToken);
  } , [loginState])
  return (
    <>
    <header>
      <div className='mina-logo'>
        <img src={'/images/logo.svg'} className='App-logo' alt='logo' />
      </div>
      <div className='mina-navigation d-flex justify-content-between w-100'>
        <ul className='mina-menu d-flex align-items-center'>
          {
          (loginState) ?
          renderMenuAuth() : renderMenu()
          }
        </ul>
        <div className='mina-account cursor-pointer'>
          <span onClick={toggleMenu}>{(loginState) ? `Hi, ${account.name}` : `Profile`}</span> <img src={'/images/user.svg'} alt="user profile" onClick={toggleMenu}/>
          <div className={`mina-account-dropdown ${showAccountForm && 'show'}`}>
            {
            (loginState) ?
            <div className='p-1 m-0'>
              <ul className="list-group border-0 text-end">
                <li className="list-group-item border-0 border-bottom text-end">Profile <i className="bi bi-person-circle"></i></li>
                <li className="list-group-item border-0 border-bottom">Change Password <i className="bi bi-key"></i></li>
                <li className="list-group-item border-0" onClick={doLogout}>Logout <i className="bi bi-box-arrow-right"></i></li>
              </ul>
            </div>
            :
            (<div>
              <h6>Login</h6>
              <div className='form-group'>
                <label className='control-label'>Username</label>
                <input type='text' className='form-control' name="username" value={username} onChange={e => setUsername(e.target.value)}/>
              </div>
              <div className='form-group'>
                <label className='control-label'>Password</label>
                <input type='password' className='form-control' name="password" value={password} onChange={e => setPassword(e.target.value)}/>
              </div>
              <div className='mina-form-group-nav form-group d-flex justify-content-between align-items-center'>
                <Link to='/forgot-password'>Lupa Password ?</Link>
                <button className='btn btn-primary' onClick={doLogin}>Log In</button>
              </div>
            </div>)
            }
          </div>
        </div>
      </div>
    </header>
    <ToastContainer position={'bottom-end'} className='position-fixed mina-toast'>
      <Toast className="d-inline-block m-1" bg={info.bg} onClose={() => setShowInfo(false)} show={showInfo} delay={3000} autohide>
      <Toast.Header>
        <strong className="me-auto">{info.title}</strong>
      </Toast.Header>
      <Toast.Body className='text-light'>
        {info.msg}
      </Toast.Body>
      </Toast>
    </ToastContainer>
    </>
  )
}