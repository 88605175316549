import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Breadcumb from '../../../components/layout/breadcumb';
import Table from '../../../components/global/Table';
import ToasterInfo from '../../../components/global/ToasterInfo';
import DeletePrompt from '../../../components/global/DeletePrompt';
import axios from '../../../config/axios';
import {Link} from 'react-router-dom';
import Select from 'react-select';
export default function MenuItems() {
  const [isUpdate, setIsUpdate] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState('');
  const [id_menu, setId_Menu] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [target, setTarget] = useState('');
  const [type, setType] = useState('');
  const [id_parent, setId_Parent] = useState('');
  const [menuitems, setMenuItems] = useState([]);
  const [optMenuItems, setOptMenuItems] = useState([]);
  const [menu, setMenu] = useState([]);
  const [optMenu, setOptMenu] = useState([]);
  const options = [
    {value: 'Button', label: 'Button'},
    {value: 'Link', label: 'Link'}
  ];
  const options2 = [
    {value: '1', label: 'Button'},
    {value: '2', label: 'Link'},
    {value: '2', label: 'Link'},
    {value: '2', label: 'Link'},
    {value: '2', label: 'Link'},
  ];
  // const Button = button
  const [breadCumbLinks] = useState([
    {
      path: '/administration',
      title: 'Administration'
    },
    {
      path: '/administration/settings`',
      title: 'Settings'
    },
    {
      path: '/administration/settings/menuitems`',
      title: 'Menuitems'
    }
  ])
  const [tableHeading] = useState([
    {
      key: 'id',
      label: 'No',
      sort: false,
      width: '5%'
    },
    {
      key: 'menu',
      label: 'Menu Group',
      sort: true
    },
    {
      key: 'name',
      label: 'Name',
      sort: true
    },
    {
        key: 'description',
        label: 'Description',
        sort: true
    },
    {
        key: 'target',
        label: 'Target',
        sort: true
    },
    {
        key: 'type',
        label: 'Type',
        sort: true
    },
    {
        key: 'menuitems',
        label: 'Parent',
        sort: true
    },
    {
      key: 'action',
      label: 'Action',
      sort: false
    }
  ])
  const [tableBody, setTableBody] = useState([])
  const [tablePaging, setTablePaging] = useState({
    currentPage: 1,
    perPage: 10,
    sortBy: 'id',
    sortDir: 'desc',
    search: null,
    totalRows: 0,
    totalPages: 0,
    perPageOptions: [10, 20, 50, 100]
  })
  const tableAction = [ 'update','delete']
  const [info, setInfo] = useState({
    title: '',
    msg: '',
    bg: 'Primary',
    show: false
  });
  const onTableChange = (paging) => {
    doGet(paging)
  }
  const toggleForm = () => { setShowForm(!showForm) }
  const toggleDelete = () => { setShowDelete(!showDelete) }
  const doGetMenuItems = async () => {
    try {
      let url = '/MenuItems/list'
      let payload = {
        currentPage: 1,
        perPage: 1000,
        sortBy: 'id',
        sortDesc: true,
        search: ''
      }
      let res = await axios.post(url, payload)
      if(res.status == 200){
        let data = res.data.data
        let opt = data.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setOptMenuItems(opt)
      }
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Failed to get Role List Data',
        bg: 'danger',
        show: true
      })
    }
  }

  const doGetMenu = async () => {
    try {
      let url = '/Menu/list'
      let payload = {
        currentPage: 1,
        perPage: 1000,
        sortBy: 'id',
        sortDesc: true,
        search: ''
      }
      let res = await axios.post(url, payload)
      if(res.status == 200){
        let data = res.data.data
        let opt = data.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setOptMenu(opt)
      }
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Failed to get Role List Data',
        bg: 'danger',
        show: true
      })
    }
  }

  const doGet = async (paging) => {
    try {
      let url = '/MenuItems/list'
      let payload = (paging) ? {...paging} : {...tablePaging}
      let res = await axios.post(url, payload)
      if(res.status == 200){
        let {data,paging} = res.data
        setTableBody(data)
        let newPaging = {...tablePaging, ...paging}
        setTablePaging(newPaging)
      }
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Failed to get List Data',
        bg: 'danger',
        show: true
      })
    }
  }

  const doSave = async () => {
    setLoading(true)
    let menuitemss = []
    menuitems.map(item => {
      menuitemss.push(item.value)
    });
    let menus = []
    menus.map(item => {
      menus.push(item.value)
    });
    let payload = new FormData();
    payload.append('menu', menus);
    payload.append('name', name);
    payload.append('description', description);
    payload.append('target', target);
    payload.append('type', type);
    payload.append('menuitems', menuitemss);
    let url = '/MenuItems/create';
    if(isUpdate){
      url = '/menu/update'
      payload.append('idmenuitems', id);
    }
    let headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    try {
      let response = await axios.post(url, payload, headers);
      if(response.status === 200) {
        doGet()
        toggleForm()
        clearForm()
        setInfo({
          title: 'Success',
          msg: 'Data Successfully Saved',
          bg: 'success',
          show: true
        })
      }
      setLoading(false)
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Data Failed to Save',
        bg: 'danger',
        show: true
      })
      setLoading(false)
    } 
  }

  const doCreate = () => {
    setIsUpdate(false)
    toggleForm()
  }

  const doUpdate = () => {
    
  }

  const doDelete = async (prompt,item) => {
    if(prompt){
      setDeleteItem(item)
      toggleDelete()
    } else {
      try {
        let res = await axios.get(`/MenuItems/${deleteItem.id}`)
        if(res.status === 200){
          doGet()
          setInfo({
            title: 'Success',
            msg: 'Data Successfully Deleted',
            bg: 'success',
            show: true
          })
        } else {
          setInfo({
            title: 'Error',
            msg: 'Data Failed to Delete',
            bg: 'danger',
            show: true
          })
        }
      } catch (error) {
        setInfo({
          title: 'Error',
          msg: 'Internal server error',
          bg: 'danger',
          show: true
        })
      }
      toggleDelete()
    }
  }

  const clearForm = () => {
    setMenu('')
    setName('')
    setDescription('')
    setTarget('')
    setType('')
    setMenuItems('')
  }
  useEffect(() => {
    doGet()
    doGetMenuItems()
    doGetMenu();
  }, [])
  return (
      <>
        <Breadcumb title="Menu Items" links={breadCumbLinks}/>
        <div>
          <div className='mina-table-filter p-3 my-4'>
            <h6>Filter</h6>
            <div className='row g-2 align-items-end'>
              <div className='col-4'>
                <label className="form-label">Menu</label>
                <select type="email" className="form-control">
                  <option>Select</option>
                </select>
              </div>
              <div className='col-4'>
                <label className="form-label">Status</label>
                <select type="email" className="form-control">
                  <option>Select</option>
                </select>
              </div>
              <div className='col-4 d-inline-flex justify-content-end'>
                <button className='btn btn-primary ms-1' onClick={toggleForm} type='button'><i className="bi bi-plus-circle"></i> Add New</button>
              </div>
            </div>
          </div>
          <Table 
            heading={tableHeading}
            body={tableBody} 
            paging={tablePaging}
            action={tableAction}
            onUpdate={(val) => {doUpdate(val)}}
            onDelete={(val) => {doDelete(true,val)}}
            onTableChange={(val) => {onTableChange(val)}}>
          </Table>
        </div>
        <Modal show={showForm} onHide={toggleForm} centered size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Form Menu items</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row g-1'>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Menu Group</label>
                <Select
                  placeholder="Select Menu"
                  options={optMenu}
                  onChange={(val) => {setMenu(val)}}
                />
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Name</label>
                <input className='form-control' onChange={(e)=>{setName(e.target.value)}} value={name}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Description</label>
                <input className='form-control' onChange={(e)=>{setDescription(e.target.value)}} value={description}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Target</label>
                <input className='form-control' onChange={(e)=>{setTarget(e.target.value)}} value={target}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Type</label>
                <Select 
                  placeholder="Select Type"
                  options= {options}
                  />
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Parent Field</label>
                <Select
                  placeholder="Select Menu Items"
                  options={optMenuItems}
                  onChange={(val) => {setMenuItems(val)}}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleForm}>
              Cancel
            </Button>
            <Button variant="primary" onClick={doSave} disabled={loading}>
            {(loading) ? 'Processing...': 'Save'}
            </Button>
          </Modal.Footer>
        </Modal>
        <DeletePrompt show={showDelete} onHide={() => {toggleDelete()}} onDelete={() => {doDelete(false,deleteItem)}}/>
        <ToasterInfo title={info.title} msg={info.msg} bg={info.bg} show={info.show}/>
      </>
  )
}