import {useState} from 'react'
import { Modal, Button } from 'react-bootstrap';
export default function DeletePrompt({show, loading, onDelete, onHide}){
  const toggle = () => {
    onHide()
  }
  return (
    <Modal show={show} onHide={toggle} centered size='sm' className='border-dark'>
      <Modal.Header closeButton className='bg-warning border-dark'>
        <Modal.Title>Warning!!!</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-warning border-dark'>
        <p className='text-center m-0 p-3'>Are you sure to delete this data?</p>
      </Modal.Body>
      <Modal.Footer className='bg-warning border-dark'>
        <Button variant="success" onClick={toggle}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onDelete} disabled={loading}>
        {(loading) ? 'Processing...': 'Delete!'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}