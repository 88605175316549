import { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel'
import { Link } from 'react-router-dom';
import Footer from '../../components/layout/footer';
import axios from 'axios'
export default function Home() {
  const [activeFsCategory, setActiveFsCategory] = useState('All');
  const files = ['archive', 'audio', 'doc', 'illustrator', 'pdf', 'ppt', 'psd', 'video', 'xls'];
  const category = ['All', 'Dokumen', 'Dokumen General', 'Dokumen Spesifik', 'Weekly Meeting', 'New Project'];
  const [banner, setBanner] = useState([]);
  const [news, setNews] = useState([]);
  const [file, setFile] = useState([]);
  const [loginState, setLoginState] = useState(false);
  const [token, setToken] = useState('');
  const [account, setAccount] = useState('');
  const getBanner = async() => {
    try {
      const url = '/Banner/list'
      const payload = {
        currentPage: 1,
        perPage: 10,
        sortBy: 'id',
        sortDesc: true,
        search: '',
        totalRows: 0,
        totalPages: 0,
        perPageOptions: [10, 20, 50, 100]
      }
      const request = await axios.post(url, payload)
      if(request.status == 200){
        const {data} = request.data
        setBanner(data)
        console.log('Banner', request.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getNews = async () => {
    try {
      const url = '/News/list'
      const payload = {
        currentPage: 1,
        perPage: 3,
        sortBy: 'id',
        sortDesc: true,
        search: '',
        totalRows: 0,
        totalPages: 0,
        perPageOptions: [10, 20, 50, 100]
      }
      const request = await axios.post(url, payload)
      if(request.status == 200){
        const {data} = request.data
        setNews(data)
        console.log('News', request.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getFile = async() => {
    try {
      let url = '/Fileshare/list'
      let payload = {
        currentPage: 1,
        perPage: 9,
        sortBy: 'id',
        sortDesc: true,
        search: '',
        totalRows: 0,
        totalPages: 0,
        perPageOptions: [10, 20, 50, 100]
      }
      let request = await axios.post(url, payload)
      if(request.status == 200){
        const {data} = request.data
        setFile(data)
        console.log('File', data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getIcon = (type) => {
    if(type == '.doc' || type == '.docx'){
      return 'doc.svg'
    }
    if(type == '.xls' || type == '.xlsx'){
      return 'xls.svg'
    }
    if(type == '.pdf'){
      return 'pdf.svg'
    }
    if(type == '.jpg' || type == '.jpeg' || type == '.png' || type == '.gif' || type == '.bmp' || type == '.tiff' || type == '.svg' || type == '.webp' || type == '.ico'){
      return 'img-icon.svg'
    }
    return 'file-icon.svg'
  }
  const summary = (data) => {
    let content = extractText(data)
    var maxLength = 200
    var trimmedString = content.substr(0, maxLength);
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
    return trimmedString
  }
  const extractText = (s) => {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }
  function renderNews() {
    if(news.length > 0){
      return news.map((item, index) => {
        return (
          <div className='col-12 col-sm-4' key={index}>
            <div className='mina-ph-news-item'>
              <img src={item.image} alt="news 1"/>
              <div className='mina-ph-news-content'>
                <h5>{item.title}</h5>
                <label>
                  <img src={'/images/calendar.svg'} alt='calendar'/> {item.createddate}
                </label>
                <Link to={`/news/${item.id}`}>Selengkapnya <img src={'/images/arrow-right.svg'} alt='arrow'/></Link>
              </div>
            </div>
          </div>
        )
      })
    } else {
      return (<p className='text-center'>Loading...</p>)
    }
  }
  function renderCategory() {
    return category.map((item, index) => {
      return (
        <span className={`${(activeFsCategory === item) && 'active'}`} onClick={()=>{setActiveFsCategory(item)}} key={index}>{item}</span>
      )
    })
  }
  function renderFiles(){
    return file.map((item, index) => {
      return (
        <div className='col-12 col-sm-4' key={index}>
          <a href={item.filename} target="_blank">
            <div className='mina-ph-fswc-item d-flex align-items-center justify-content-between'>
              <img src={`/images/files/${getIcon(item.doc_type)}`} alt='File'/>
              <div className='mina-ph-fswci-content w-100'>
                <h6>{item.title}</h6>
                <label>
                  <img src={'/images/calendar.svg'} alt='calendar'/> {item.createddate}
                </label>
              </div>
            </div>
          </a>
        </div>
      )
    })
  }
  const checkLoginState = () => {
    if(localStorage.getItem('meainaLogin')){
      setLoginState(true);
      let data
      if(localStorage.getItem('meainaAccount')){
        data = localStorage.getItem('meainaAccount');
        data = JSON.parse(data);
      } else {
        data = {
          name: 'Admin',
          username: 'admin',
          email: 'admin@meaina.com',
          phone: '08123'
        }
      }
      setAccount(data.user);
    }
  }
  useEffect(() => {
    checkLoginState()
    getBanner()
    getFile()
    setTimeout(() => {
      getNews()
    },3000)
  } , [])
  return (
    <div className='mina-page-home'>
      <section className='mina-ph-carousel'>
        <Carousel fade indicators={true} controls={false}>
          {
            banner.map((item, index) => {
              return (
                <Carousel.Item key={index}>
                <img src={item.image} alt='Banner Item'/>
                <Carousel.Caption className='d-flex align-items-center'>
                  <div className='d-block'>
                    <h1>{item.heading}</h1>
                    <p>{item.subheading}</p>
                    {/* {
                    (item.btn_text) ? 
                    <Link to={(item.btn_link) ? item.btn_link : '#'} className='d-flex justify-content-center align-items-center'>{item.btn_text}</Link> : null
                    } */}
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
              )
            })
          }
          {/* <Carousel.Item>
            <img src={'/images/banner.svg'} alt='Banner Item'/>
            <Carousel.Caption className='d-flex align-items-center'>
              <div className='mina-wrap d-block mx-auto'>
                <h1>Great Start Your Morning</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget</p>
                <Link to='#' className='d-flex justify-content-center align-items-center'>Explore</Link>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={'/images/banner.svg'} alt='Banner Item'/>
            <Carousel.Caption className='d-flex align-items-center'>
              <div className='mina-wrap d-block mx-auto'>
                <h1>Think Big Doing Great</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget</p>
                <Link to='#' className='d-flex justify-content-center align-items-center'>Explore</Link>
              </div>
            </Carousel.Caption>
          </Carousel.Item> */}
        </Carousel>
      </section>
      <section className='mina-ph-news'>
        <div className='mina-wrap d-block mx-auto'>
          <h1>News</h1>
          <div className='mina-ph-news-wrap d-flex justify-content-center row'>
            {renderNews()}
          </div>
        </div>
      </section>
      {
      (loginState) ? (<section className='mina-ph-file-sharing bg-white'>
        <div className='mina-wrap d-block mx-auto'>
          <h1>File Sharing</h1>
          <div className='mina-ph-file-sharing-wrap'>
            <div className='mina-ph-fsw-tab d-flex justify-content-center align-items-center'>
              {renderCategory()}
            </div>
            <div className='mina-ph-fsw-content row'>
              {renderFiles()}
            </div>
          </div>
          <div className='text-center d-flex justify-content-center mt-3'>
            <Link to='file-sharing' className='d-flex justify-content-center align-items-center mina-ph-fs-more'>See More</Link>
          </div>
        </div>
      </section>) : null
      }
      <Footer/>
    </div>
  )
}