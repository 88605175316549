import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import Breadcumb from '../../components/layout/breadcumb';
import axios from 'axios'
export default function Details() {
  const { id } = useParams();
  const [news, setNews] = useState({})
  const getNews = async () => {
    try {
      const url = '/News/list'
      const payload = {
        currentPage: 1,
        perPage: 9,
        sortBy: 'id',
        sortDesc: true,
        search: '',
        totalRows: 0,
        totalPages: 0,
        perPageOptions: [10, 20, 50, 100]
      }
      const request = await axios.post(url, payload)
      if(request.status == 200){
        const {data} = request.data
        data.map((news, index) => {
          if(news.id == id){
            setNews(news)
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const breadCumbLinks = [
    {
      path: '/news',
      title: 'News'
    },
    {
      path: '#',
      title: 'Details'
    },
    {
      path: `/${id}`,
      title: news.title
    }
  ]
  useEffect(() => {
    getNews()
  }, []);
  return (
    <>
      <Breadcumb title="News" links={breadCumbLinks}/>
      <div className="mina-news-item mt-4 mina-news-details d-block w-100">
        <img src={news.image} alt='creator' className='img-fluid w-100'/>
        <div className="mina-news-item-content">
          <h3>{ news.title }</h3>
          <div className='mina-news-ic-title d-flex align-items-center mb-3'>
            <img src='/images/user.svg' alt='creator' className='me-3'/>
            <span className='me-1'>by</span>
            <h6 className='mb-0 me-3 pe-3 border-end'>{news.createdname}</h6>
            <label>{news.createddate}</label>
          </div>
          <div className='mina-news-ic-summary' dangerouslySetInnerHTML={{__html: news.content }}>
            {/* {news.content} */}
          </div>
        </div>
      </div>
    </>
  )
}