import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Breadcumb from '../../components/layout/breadcumb';
import axios from 'axios'
export default function Dashboard() {
  const breadCumbLinks = [
    {
      path: '/news',
      title: 'News'
    },
    {
      path: '/administration',
      title: 'Dashboard'
    }
  ]
  const [news, setNews] = useState([])
  const getNews = async () => {
    try {
      const url = '/News/list'
      const payload = {
        currentPage: 1,
        perPage: 9,
        sortBy: 'id',
        sortDesc: true,
        search: '',
        totalRows: 0,
        totalPages: 0,
        perPageOptions: [10, 20, 50, 100]
      }
      const request = await axios.post(url, payload)
      if(request.status == 200){
        const {data} = request.data
        setNews(data)
        console.log('News', request.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const summary = (data) => {
    let content = extractText(data)
    var maxLength = 200
    var trimmedString = content.substr(0, maxLength);
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
    return trimmedString
  }
  const extractText = (s) => {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }
  function renderNews() {
    return news.map((news, index) => {
      return (
        <div className="col-6 mb-5" key={index}>
          <div className="mina-news-item ">
            <img src={news.image} alt='creator' className='img-fluid w-100'/>
            <div className="mina-news-item-content">
              <h3>{ news.title }</h3>
              <div className='mina-news-ic-title d-flex align-items-center mb-3'>
                <img src='/images/user.svg' alt='creator' className='me-3'/>
                <span className='me-1'>by</span>
                <h6 className='mb-0 me-3 pe-3 border-end'>{news.createdname}</h6>
                <label>{news.createddate}</label>
              </div>
              <div className='mina-news-ic-summary' dangerouslySetInnerHTML={{__html: summary(news.content) }}>
                
              </div>
              <Link to={`/news/${news.id}`}>Read More</Link>
            </div>
          </div>
        </div>
      )
    })
  }
  useEffect(() => {
    getNews()
  }, []);
  return (
      <>
        <Breadcumb title="News" links={breadCumbLinks}/>
        <div className='mina-news-wrap row my-3'>
          {renderNews()}
        </div>
      </>
  )
}