import { useState } from 'react';
import Breadcumb from '../../components/layout/breadcumb';
import SearchForm from '../../components/layout/searchform';
export default function Applications() {
  const [breadCumbLinks] = useState(
    [
      {
        path: '/applications',
        title: 'Applications'
      }
    ]
  )
  return (
    <div className='mina-main-wrap d-flex'>
      <div className="mina-menu-left">
        <SearchForm/>
      </div>
      <div className="mina-main-content">
        <Breadcumb title="Applications" links={breadCumbLinks}/>
      </div>
    </div>
  )
}