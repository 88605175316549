import axios from '../config/axios'
const list = async (payload) => {
  let url = '/News/list'
  const req = await axios.post(url,payload);
  return req
};
const create = async (payload) => {
  let url = '/News/create'
  const req = await axios.post(url,payload);
  return req
};
const update = async (payload) => {
  let url = '/News/update'
  const req = await axios.post(url,payload);
  return req
};
const remove = async (id) => {
  let url = '/News/'+id
  const req = await axios.get(url);
  return req
};
const moreimage = async (payload) => {
  let url = '/News/moreimage'
  const req = await axios.post(url,payload);
  return req
};
export default {
  list,
  create,
  update,
  remove,
  moreimage
};