import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Breadcumb from '../../../components/layout/breadcumb';
import Table from '../../../components/global/Table';
import ToasterInfo from '../../../components/global/ToasterInfo';
import DeletePrompt from '../../../components/global/DeletePrompt';
import axios from '../../../config/axios';
import {Link} from 'react-router-dom';
export default function Menus() {
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [position, setPosition] = useState('');
  const [breadCumbLinks] = useState([
    {
      path: '/administration',
      title: 'Administration'
    },
    {
      path: '/administration/settings`',
      title: 'Settings'
    },
    {
      path: '/administration/settings/menus`',
      title: 'Menus'
    }
  ])
  const [tableHeading] = useState([
    {
      key: 'id',
      label: 'No',
      sort: false,
      width: '5%'
    },
    {
      key: 'name',
      label: 'Name',
      sort: true
    },
    {
      key: 'description',
      label: 'Description',
      sort: true
    },
    {
      key: 'action',
      label: 'Action',
      sort: false
    }
  ])
  const [tableBody, setTableBody] = useState([])
  const [tablePaging, setTablePaging] = useState({
    currentPage: 1,
    perPage: 10,
    sortBy: 'id',
    sortDir: 'desc',
    search: null,
    totalRows: 0,
    totalPages: 0,
    perPageOptions: [10, 20, 50, 100]
  })
  const tableAction = ['create-sub','update','delete']
  const [info, setInfo] = useState({
    title: '',
    msg: '',
    bg: 'Primary',
    show: false
  });
  const onTableChange = (paging) => {
    doGet(paging)
  }
  const toggleForm = () => { setShowForm(!showForm) }
  const toggleDelete = () => { setShowDelete(!showDelete) }
  const doGet = async (paging) => {
    try {
      let url = '/Menu/list'
      let payload = (paging) ? {...paging} : {...tablePaging}
      let res = await axios.post(url, payload)
      if(res.status == 200){
        let {data,paging} = res.data
        setTableBody(data)
        let newPaging = {...tablePaging, ...paging}
        setTablePaging(newPaging)
      }
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Failed to get List Data',
        bg: 'danger',
        show: true
      })
    }
  }

  const doSave = async () => {
    setLoading(true)
    let payload = new FormData();
    payload.append('name', name);
    payload.append('description', description);
    payload.append('position', position);
    let url = '/Menu/create';
    if(isUpdate){
      url = '/Menu/update'
      payload.append('idmenu', id);
    }
    let headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    try {
      let response = await axios.post(url, payload, headers);
      if(response.status === 200) {
        doGet()
        toggleForm()
        clearForm()
        setInfo({
          title: 'Success',
          msg: 'Data Successfully Saved',
          bg: 'success',
          show: true
        })
      }
      setLoading(false)
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Data Failed to Save',
        bg: 'danger',
        show: true
      })
      setLoading(false)
    }
  }

  const doUpdate = async (item) => {
    let updateData = {...item}
    setId(updateData.id)
    setName(updateData.name)
    setDescription(updateData.description)
    setIsUpdate(true)
    toggleForm()
  }

  const doDelete = async (prompt,item) => {
    if(prompt){
      setDeleteItem(item)
      toggleDelete()
    } else {
      try {
        let res = await axios.get(`/Menu/${deleteItem.id}`)
        if(res.status === 200){
          doGet()
          setInfo({
            title: 'Success',
            msg: 'Data Successfully Deleted',
            bg: 'success',
            show: true
          })
        } else {
          setInfo({
            title: 'Error',
            msg: 'Data Failed to Delete',
            bg: 'danger',
            show: true
          })
        }
      } catch (error) {
        setInfo({
          title: 'Error',
          msg: 'Internal server error',
          bg: 'danger',
          show: true
        })
      }
      toggleDelete()
    }
  }

  const clearForm = () => {
    setName('')
    setDescription('')
    setPosition('')
  }
  useEffect(() => {
    doGet()
  }, [])
  return (
      <>
        <Breadcumb title="Menus" links={breadCumbLinks}/>
        <div>
          <div className='mina-table-filter p-3 my-4'>
            <h6>Filter</h6>
            <div className='row g-2 align-items-end'>
              <div className='col-4'>
                <label className="form-label">Menu</label>
                <select type="email" className="form-control">
                  <option>Select</option>
                </select>
              </div>
              <div className='col-4'>
                <label className="form-label">Status</label>
                <select type="email" className="form-control">
                  <option>Select</option>
                </select>
              </div>
              <div className='col-4 d-inline-flex justify-content-end'>
                <button className='btn btn-primary ms-1' onClick={toggleForm} type='button'><i className="bi bi-plus-circle"></i> Add New</button>
              </div>
            </div>
          </div>
          <Table 
            heading={tableHeading}
            body={tableBody} 
            paging={tablePaging}
            action={tableAction}
            onUpdate={(val) => {doUpdate(val)}}
            onDelete={(val) => {doDelete(true,val)}} 
            onTableChange={(val) => {onTableChange(val)}}>
          </Table>
        </div>
        <Modal show={showForm} onHide={toggleForm} centered size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Form Menus</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row g-2'>
              <div className='col-12 col'>
                <label className="form-label">Name</label>
                <input className='form-control' onChange={(e)=>{setName(e.target.value)}} value={name}/>
              </div>
              <div className='col-12 col'>
                <label className="form-label">Description</label>
                <textarea className='form-control' onChange={(e)=>{setDescription(e.target.value)}} value={description}/>
              </div>
              {/* <div className='col-12 col'>
                <label className="form-label">Position</label>
                <input className='form-control' onChange={(e)=>{setPosition(e.target.value)}} value={position}/>
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleForm}>
              Cancel
            </Button>
            <Button variant="primary" onClick={doSave} disabled={loading}>
            {(loading) ? 'Processing...': 'Save'}
            </Button>
          </Modal.Footer>
        </Modal>
        <DeletePrompt show={showDelete} onHide={() => {toggleDelete()}} onDelete={() => {doDelete(false,deleteItem)}}/>
        <ToasterInfo title={info.title} msg={info.msg} bg={info.bg} show={info.show}/>
      </>
  )
}