import { useEffect, useState } from 'react';
import Breadcumb from '../../components/layout/breadcumb';
import axios from 'axios'
export default function Dashboard() {
  const breadCumbLinks = [
    {
      path: '/administration',
      title: 'Administration'
    },
    {
      path: '/administration',
      title: 'Dashboard'
    }
  ]
  const [file, setFile] = useState([])
  const newFile = [
    {
      fileName: 'Formulir Pendaftaran Vaksin',
      fileType: 'doc',
      fileDesc: 'Dibuat 3 Menit yang lalu',
      fileIcon: 'doc.svg'
    },
    {
      fileName: 'Daftar Penerima Vaksin Gelombang 1',
      fileType: 'sheet',
      fileDesc: 'Dibuat 3 Menit yang lalu',
      fileIcon: 'xls.svg'
    },
    {
      fileName: 'Informasi Jadwal Vaksin',
      fileType: 'pdf',
      fileDesc: 'Dibuat 3 Menit yang lalu',
      fileIcon: 'pdf.svg'
    },
    {
      fileName: 'Pengumuman Penerima Bantuan Vaksin',
      fileType: 'persentation',
      fileDesc: 'Dibuat 3 Menit yang lalu',
      fileIcon: 'ppt.svg'
    }
  ]
  const table = {
    thead: ['Title', 'Description', 'Pembuat', 'Tanggal Dibuat', 'Ukuran File'],
    tbody: [
      {
        fileName: 'Formulir Pendaftaran Vaksin',
        fileCreatedBy: 'Admin',
        fileCreatedDate: '3 Menit yang lalu',
        fileSize: '1.5 MB',
        fileIcon: 'doc.svg'
      },
      {
        fileName: 'Formulir Pendaftaran Vaksin',
        fileCreatedBy: 'Admin',
        fileCreatedDate: '3 Menit yang lalu',
        fileSize: '1.5 MB',
        fileIcon: 'ppt.svg'
      },
      {
        fileName: 'Formulir Pendaftaran Vaksin',
        fileCreatedBy: 'Admin',
        fileCreatedDate: '3 Menit yang lalu',
        fileSize: '1.5 MB',
        fileIcon: 'pdf.svg'
      },
      {
        fileName: 'Formulir Pendaftaran Vaksin',
        fileCreatedBy: 'Admin',
        fileCreatedDate: '3 Menit yang lalu',
        fileSize: '1.5 MB',
        fileIcon: 'xls.svg'
      },
      {
        fileName: 'Formulir Pendaftaran Vaksin',
        fileCreatedBy: 'Admin',
        fileCreatedDate: '3 Menit yang lalu',
        fileSize: '1.5 MB',
        fileIcon: 'audio.svg'
      },
      {
        fileName: 'Formulir Pendaftaran Vaksin',
        fileCreatedBy: 'Admin',
        fileCreatedDate: '3 Menit yang lalu',
        fileSize: '1.5 MB',
        fileIcon: 'video.svg'
      }
    ]
  }
  const getFile = async() => {
    try {
      let url = '/Fileshare/list'
      let payload = {
        currentPage: 1,
        perPage: 10,
        sortBy: 'id',
        sortDesc: true,
        search: '',
        totalRows: 0,
        totalPages: 0,
        perPageOptions: [10, 20, 50, 100]
      }
      let request = await axios.post(url, payload)
      if(request.status == 200){
        const {data} = request.data
        setFile(data)
        console.log('File', data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getIcon = (type) => {
    if(type == '.doc' || type == '.docx'){
      return 'doc.svg'
    }
    if(type == '.xls' || type == '.xlsx'){
      return 'xls.svg'
    }
    if(type == '.pdf'){
      return 'pdf.svg'
    }
    if(type == '.jpg' || type == '.jpeg' || type == '.png' || type == '.gif' || type == '.bmp' || type == '.tiff' || type == '.svg' || type == '.webp' || type == '.ico'){
      return 'img-icon.svg'
    }
    return 'file-icon.svg'
  }
  useEffect(() => {
    getFile()
  }, [])
  return (
      <>
        <Breadcumb title="Administration" links={breadCumbLinks}/>
        <div className='mina-filesharing-dashboard'>
          <div className='mina-filesharing-summary mt-4 mb-4'>
            <h6 className='mb-4'>Terbaru</h6>
            <div className='row'>
              {file.map((file, index) => {
                if(index < 4){
                  return(
                    <div className='cols-6 col-sm-3' key={index}>
                      <a href={file.filename} target="_blank">
                      <div className={`mina-filesharing-summary-item d-flex flex-column justify-content-between align-items-start item-${file.fileType}`}>
                        <img src={`/images/files/${getIcon(file.doc_type)}`} alt='icon'/>
                        <div>
                          <h6>{file.title}</h6>
                          <span>{file.doc_type}</span>
                        </div>
                      </div>
                      </a>
                    </div>
                  )
                }
              })}
            </div>
          </div>
          <div className='mina-filesharing-list table-responsive pt-4'>
            <table className='table'>
              <thead>
                <tr>
                  {
                    table.thead.map((item, index) => {
                      return(
                        <th key={index} className='text-left'>{item}</th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody>
                  {
                    file.map((item, index) => {
                      return(
                        <tr key={index}>
                          <td>
                            <a href={item.filename} target="_blank">
                            <img src={`/images/files/${getIcon(item.doc_type)}`} alt='icon' className='me-2'/>
                            {item.title}
                            </a>
                          </td>
                          <td>{item.description}</td>
                          <td>{item.createdname}</td>
                          <td>{item.createddate}</td>
                          <td>{item.doc_size}</td>
                        </tr>
                      )
                    })
                  }
              </tbody>
            </table>
          </div>
        </div>
      </>
  )
}