import Home from '../pages/Home'
import News from '../pages/News'
import DashboardNews from '../pages/News/Dashboard'
import DetailsNews from '../pages/News/Details'
import FileSharing from '../pages/FileSharing'
import DashboardFileSharing from '../pages/FileSharing/Dashboard'
import Applications from '../pages/Applications'
import Administration from '../pages/Administration'
import DashboardAdministration from '../pages/Administration/Dashboard'
import Banner from '../pages/Administration/Banner'
import NewsAdmin from '../pages/Administration/NewsAdmin'
import FileSharingAdmin from '../pages/Administration/FileSharing'
import Settings from '../pages/Administration/Settings'
import Users from '../pages/Administration/Settings/Users'
import Groups from '../pages/Administration/Settings/Groups'
import Menus from '../pages/Administration/Settings/Menus'
import MenuItems   from '../pages/Administration/Settings/MenuItems'
import UserAccess from '../pages/Administration/Settings/UserAccess'
const menu = [
  {
    name: 'Home',
    path: '/',
    key: 'home',
    components: Home,
  },
  {
    name: 'News',
    path: '/news',
    key: 'news',
    components: News,
    children: [
      {
        name: 'Dashboard News',
        path: '',
        key: 'dashboard-news',
        index: true,
        components: DashboardNews,
      },
      {
        name: 'Details News',
        path: ':id',
        key: 'details',
        components: DetailsNews,
      },
    ]
  },
  {
    name: 'File Sharing',
    path: '/file-sharing',
    key: 'file-sharing',
    components: FileSharing,
    auth: true,
    children: [
      {
        name: 'Dashboard Filesharing',
        path: '',
        key: 'dashboard-filesharing',
        index: true,
        components: DashboardFileSharing,
      },
    ]
  },
  {
    name: 'Applications',
    path: '/applications',
    key: 'applications',
    auth: true,
    components: Applications,
  },
  {
    name: 'Administration',
    path: '/administration',
    key: 'administration',
    auth: true,
    components: Administration,
    children: [
      // {
      //   name: 'Dashboard',
      //   path: '',
      //   key: 'dashboard',
      //   index: true,
      //   components: DashboardAdministration,
      // },
      {
        name: 'Banner',
        path: '',
        key: 'banner',
        index: true,
        components: Banner,
      },
      {
        name: 'News',
        path: 'news',
        key: 'news',
        components: NewsAdmin,
      },
      {
        name: 'FileSharing',
        path: 'file-sharing',
        key: 'file-sharing',
        components: FileSharingAdmin,
      },
      {
        name: 'Settings',
        path: 'settings',
        key: 'settings',
        components: Settings,
        children: [
          {
            name: 'Users',
            path: 'users',
            key: 'users',
            components: Users,
          },
          {
            name: 'Groups',
            path: 'groups',
            key: 'groups',
            components: Groups,
          },
          {
            name: 'Menus',
            path: 'menus',
            key: 'menus',
            components: Menus,
          },
          {
            name: 'Config Menu',
            path: 'config-menu',
            key: 'config-menu',
            components: UserAccess,
          },
          {
            // name: 'MenuItems',
            path: 'menuitems',
            key: 'menuitems',
            components: MenuItems,
          },
        ]
      },
    ]
  }
]
export default menu