export default function searchform(){
  return (
    <div className="mina-search-form">
      <div className="input-group">
        <input type="text" className="form-control" placeholder="Search"/>
        <div className="input-group-text">
          <img src="/images/search.svg" alt="icon"/>
        </div>
      </div>
    </div>
  )
}