import { useState, useEffect } from "react";
export default function ImageUpload({onChange, multiple, previewImage}) {
  const [image, setImage] = useState((previewImage) ? previewImage : 'http://via.placeholder.com/1500x600');
  const [images, setImages] = useState(previewImage);
  const [previewImages, setPreviewImages] = useState(previewImage);
  const [isMultiple, setisMultiple] = useState(multiple);
  const handleImage = (val) => {
    if(val){
      setImage(URL.createObjectURL(val))
      onChange(val)
    }
  }
  const handleImages = (val) => {
    let theImages = [...images]
    let thePreviewImages = [...previewImages]
    const uploaded = [...val.target.files]
    uploaded.map((file) => {
      theImages.push(file)
      thePreviewImages.push(URL.createObjectURL(file))
    })
    setImages(theImages)
    setPreviewImages(thePreviewImages)
    onChange(theImages)
  }
  const deleteImages = (index) => {
    let theImages = [...images]
    let thePreviewImages = [...previewImages]
    theImages.splice(index, 1)
    thePreviewImages.splice(index, 1)
    setPreviewImages(thePreviewImages)
    setImages(theImages)
    onChange(theImages)
  }
  useEffect(() => {
    
  }, [])
  return (
    <>
      {!isMultiple ? (
      <label className="cursor-pointer w-100">
        <img src={image} className='img-fluid w-100 img-thumbnail'/>
        <input type='file' onChange={(e) => {handleImage(e.target.files[0])}} className='d-none'/>
      </label> ) : (
        <div className="row">
          {
            previewImages.map((val, index) => {
              return (
                <div className="col-3 mb-3" key={index}>
                  <img src={val} className='img-fluid w-100 img-thumbnail preview-multiple mina-image-preview-thumb'/>
                  <button className="btn btn-danger btn-sm blockp-1 w-100 mt-1" onClick={()=>{deleteImages(index)}}>DELETE</button>
                </div>
              )
            })
          }
          <div className="col-12">
            <label className="cursor-pointer w-100 btn btn-primary">
              Add More Images
              <input type='file' onChange={(e) => {handleImages(e)}} className='d-none' multiple/>
            </label>
          </div>
        </div>
      )}
    </>
  )
}