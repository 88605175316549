import { useState, useEffect } from 'react';
import SearchForm from '../../components/layout/searchform';
import { Link, Outlet } from 'react-router-dom';
import menu from '../../config/menu';
export default function Administration() {
  const [activeMenu, setActiveMenu] = useState('')
  const [listMenu, setListMenu] = useState([])
  useEffect(() => {
    let theMenu = menu.filter(obj => {
      return obj.name === 'Administration'
    })
    setListMenu(theMenu[0].children)
  },[]);
  function renderMenu(menuList, menuParent='administration'){
    return menuList.map((menu, index) => {
      return (
        <li key={index}>
          {
          (menu.children) 
          ? (<span onClick={()=>{setActiveMenu((activeMenu !== menu.name) ? menu.name : '')}} className={`${(activeMenu === menu.name) && 'active'}`}>{menu.name}</span>)
          : (<Link to={`/${menuParent}/${menu.path}`}>{menu.name}</Link>)
          }
          {
          (menu.children) && 
          <ul className={`mina-menu-list mina-submenu ${(activeMenu === menu.name) && 'active'}`}>
            {renderMenu(menu.children, `${menuParent}/${menu.path}`)}
          </ul>
          }
        </li>
      )
    })
  }
  return (
    <div className='mina-main-wrap d-flex'>
      <div className="mina-menu-left">
        <SearchForm/>
        <div className='mt-5 pt-3'>
          <h6>Menu</h6>
          <ul className='mina-menu-list'>
            {renderMenu(listMenu)}
          </ul>
        </div>
      </div>
      <div className="mina-main-content w-100">
        <Outlet/>
      </div>
    </div>
  )
}