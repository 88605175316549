
import { useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
export default function ToasterInfo({setShowInfo,...props}) {
  let {title, msg, bg, show} = props;
  return (
    <ToastContainer position={'bottom-end'} className='position-fixed mina-toast'>
      <Toast className="d-inline-block m-1" bg={bg} onClose={() => setShowInfo(false)} show={show} delay={3000} autohide>
      <Toast.Header>
        <strong className="me-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body className='text-light'>
        {msg}
      </Toast.Body>
      </Toast>
    </ToastContainer>
  )
}