import {useEffect} from 'react'
import './assets/scss/main.scss'
import Header from './components/layout/header';
import Routing from './config/router';
import {
  useLocation
} from "react-router-dom";
function App() {
  let location = useLocation()
  useEffect(() => {
    const loc = location.pathname.split('/')
    if(loc.length > 1){
      if(loc[1] === 'home' || loc[1] === ''){
        document.body.classList.remove('adminLayout')
      } else {
        document.body.classList.add('adminLayout')
      }
    }
  },[location])
  return (
    // <Router>
      <div className="meainaIntrawebApp">
        <Header/>
        <div className="mina-content">
          <Routing/>
        </div>
      </div>
    // </Router>
  );
}

export default App;
