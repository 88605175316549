import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Breadcumb from '../../../components/layout/breadcumb';
import Table from '../../../components/global/Table';
import ToasterInfo from '../../../components/global/ToasterInfo';
import axios from '../../../config/axios';
export default function UserAccess() {
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [breadCumbLinks] = useState([
    {
      path: '/administration',
      title: 'Administration'
    },
    {
      path: '/administration/settings`',
      title: 'Settings'
    },
    {
      path: '/administration/settings/config-menu`',
      title: 'Config Menu'
    }
  ])
  const [tableHeading] = useState([
    {
      key: 'id',
      label: 'No',
      sort: true,
      width: '5%'
    },
    {
      key: 'name',
      label: 'Name',
      sort: true
    },
    {
      key: 'description',
      label: 'Description',
      sort: true
    },
    {
      key: 'action',
      label: 'Action',
      sort: false
    }
  ])
  const [tableBody, setTableBody] = useState([]);
  const [tablePaging, setTablePaging] = useState({
    currentPage: 1,
    perPage: 10,
    sortBy: 'id',
    sortDesc: true,
    search: '',
    totalRows: 0,
    totalPages: 0,
    perPageOptions: [10, 20, 50, 100]
  })
  const [info, setInfo] = useState({
    title: '',
    msg: '',
    bg: 'Primary',
    show: false
  });
  const onTableChange = (paging) => {
    doGet(paging)
  }
  const toggleForm = () => { setShowForm(!showForm) }
  const doGet = async (paging) => {
    try {
      let url = '/Group/list'
      let payload = (paging) ? {...paging} : {...tablePaging}
      let res = await axios.post(url, payload)
      if(res.status == 200){
        let {data,paging} = res.data
        setTableBody(data)
        let newPaging = {...tablePaging, ...paging}
        setTablePaging(newPaging)
      }
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Failed to get List Data',
        bg: 'danger',
        show: true
      })
    }
  }
  const doSave = async () => {
    setLoading(true)
    let payload = new FormData();
    payload.append('groupname', name);
    payload.append('groupdesc', description);
    payload.append('createdby', 1)
    let url = '/Group/create';
    let headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    try {
      let response = await axios.post(url, payload, headers);
      if(response.status === 200) {
        doGet()
        toggleForm()
        clearForm()
        setInfo({
          title: 'Success',
          msg: 'Data Successfully Saved',
          bg: 'success',
          show: true
        })
      }
      setLoading(false)
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Data Failed to Save',
        bg: 'danger',
        show: true
      })
      setLoading(false)
    }
  }
  const clearForm = () => {
    setName('')
    setDescription('')
  }
  useEffect(() => {
    doGet()
  }, [])
  return (
      <>
        <Breadcumb title="Config Menu" links={breadCumbLinks}/>
        <div>
          <div className="d-flex flex-row justify-content-between w-100">
            <div className='mina-table-filter p-3 my-4 mx-2 w-50'>
              <div className="d-flex flex-row justify-content-start">
                <button class="btn btn-primary btn-sm me-2" type="button"><i class="bi bi-plus-circle"></i> Add New</button>
                <button class="btn btn-primary btn-sm me-2" type="button"><i class="bi bi-pencil"></i> Edit</button>
                <button class="btn btn-primary btn-sm me-2" type="button"><i class="bi bi-trash"></i> Delete</button>
              </div>

              <div className="mt-4">
                <ul>
                  <li className="mb-1">Super Admin</li>
                  <li className="mb-1">Admin HR</li>
                  <li className="mb-1">Staff Admin</li>
                  <li className="mb-1">Staff Invoice</li>
                  <li className="mb-1">Staff Accounting</li>
                  <li className="mb-1">File Sharing Weekly Meeting</li>
                  <li className="mb-1">File Sharing New Project</li>
                  <li className="mb-1">File Sharing Document</li>
                </ul>
              </div>
              
            </div>

            <div className='mina-table-filter p-3 my-4 mx-2 w-50'>
              <div className="d-flex flex-row">
                <div style={{width: "80%"}}>
                  <select type="filter-role-option" className="form-control">
                    <option>Select</option>
                  </select>
                </div>
                <div style={{width: "20%"}} className="ms-2">
                  <button class="btn btn-primary btn-sm me-2" type="button"><i class="bi bi-trash"></i> Delete</button>
                </div>
              </div>

              <div className="mt-2">
                <p>Select role option!</p>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item border-0">
                    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
                    <span className="ms-4">Portal Intraweb </span> 
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item border-0 ms-4">
                        <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
                        <span className="ms-4">View News </span>   
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item border-0 ms-4">
                            <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
                            <span className="ms-4">Procedure </span> 
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li className="list-group-item border-0" style={{marginTop: "-25px"}}>
                    <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
                    <span className="ms-4">Invoice Application </span> 
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item border-0 ms-4">
                        <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
                        <span className="ms-4">Transaksi </span>   
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item border-0 ms-4">
                            <input className="form-check-input me-1" type="checkbox" value="" aria-label="..." />
                            <span className="ms-4">Create invoice PCS </span> 
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
         </div>
      </>
  )
}