import { Outlet } from 'react-router-dom';
import SearchForm from '../../components/layout/searchform';
import FileSharingSidebar from '../../components/global/FileSharingSidebar';
export default function FileSharing() {
  return (
    <div className='mina-main-wrap d-flex'>
      <div className="mina-menu-left">
        <SearchForm/>
        <FileSharingSidebar/>
      </div>
      <div className="mina-main-content w-100">
        <Outlet/>
      </div>
    </div>
  )
}