import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Breadcumb from '../../components/layout/breadcumb';
import Table from '../../components/global/Table';
import ImageUpload from '../../components/global/ImageUpload';
import ToasterInfo from '../../components/global/ToasterInfo';
import DeletePrompt from '../../components/global/DeletePrompt';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CreatableSelect from 'react-select/creatable';
import axios from '../../config/axios';
import { NewsServices } from '../../services';
import userStore from '../../stores/userStore';
export default function News() {
  const [account, setAccount] = useState(userStore.getAccount());
  const [token, setToken] = useState(userStore.getToken());
  const [isUpdate, setIsUpdate] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [id, setId] = useState('');
  const [image, setImage] = useState('');
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState('');
  const [loading, setLoading] = useState(false);
  const [optCategory] = useState([
    {
      value: 1,
      label: 'Internal'
    },
    {
      value: 2,
      label: 'External'
    },
    {
      value: 3,
      label: 'News'
    },
    {
      value: 4,
      label: 'Event'
    },
    {
      value: 5,
      label: 'Announcement'
    }
  ]);
  const [breadCumbLinks] = useState([
    {
      path: '/administration',
      title: 'Administration'
    },
    {
      path: '/administration/news',
      title: 'News'
    }
  ])
  const [tableHeading] = useState([
    {
      key: 'id',
      label: 'No',
      sort: false,
      width: '5%'
    },
    {
      key: 'image',
      label: 'Image',
      sort: false
    },
    {
      key: 'title',
      label: 'Title',
      sort: true
    },
    {
      key: 'tag',
      label: 'Tag',
      sort: true
    },
    {
      key: 'createdname',
      label: 'Dibuat Oleh',
      sort: true
    },
    {
      key: 'createddate',
      label: 'Dibuat Tanggal',
      sort: true
    },
    {
      key: 'action',
      label: 'Action',
      sort: false
    }
  ])
  const [tableBody, setTableBody] = useState([])
  const [tablePaging, setTablePaging] = useState({
    currentPage: 1,
    perPage: 10,
    sortBy: 'id',
    sortDesc: true,
    search: '',
    totalRows: 0,
    totalPages: 0,
    perPageOptions: [10, 20, 50, 100]
  })
  const tableAction = ['update', 'delete']
  const [info, setInfo] = useState({
    title: '',
    msg: '',
    bg: 'Primary',
    show: false
  });
  const onTableChange = (paging) => {
    doGet(paging)
  }
  const toggleForm = () => { setShowForm(!showForm) }
  const toggleDelete = () => { setShowDelete(!showDelete) }
  const handleImages = (theImages) => {
    setImages(theImages)
  }
  const doGet = async (paging) => {
    try {
      let payload = (paging) ? {...paging} : {...tablePaging}
      let res = await NewsServices.list(payload)
      if(res.status == 200){
        let {data,paging} = res.data
        data.map(async (item) => {
          item.image = <img src={item.image} alt={item.heading} width="100" />
          let images = await doGetImages(item.id)
          item.images = images
        })
        setTableBody(data)
        let newPaging = {...tablePaging, ...paging}
        setTablePaging(newPaging)
      }
    } catch (error) {
      console.log(error)
      setInfo({
        title: 'Error',
        msg: 'Failed to get List Data',
        bg: 'danger',
        show: true
      })
    }
  }
  const doGetImages = async (newsId) => {
    try {
      let payload = new FormData()
      payload.append('id', newsId)
      let res = await NewsServices.moreimage(payload)
      if(res.status == 200){
        let moreImages = res.data.data
        let updateImages = []
        moreImages.map((item) => {
          updateImages.push(item.urlimage.image)
        })
        return updateImages
      }
    } catch (error) {
      return []
    }
    return []
  }
  const doSave = async () => {
    let headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    let url = '/News/create';
    let payload = new FormData();
    let thecategory = []
    let thetags = []
    category.map((item) => { thecategory.push(item.value) })
    tags.map((item) => { thetags.push(item.value) })
    payload.append('title', title);
    payload.append('content', content);
    payload.append('id_kategori', thecategory.toString());
    payload.append('tag', thetags.toString());
    if(isUpdate){
      payload.append('idns', id)
      payload.append('updatedby', account.id)
      url = '/News/update'
    } else {
      payload.append('createdby', account.id)
    }
    if(typeof(image) == 'object'){
      payload.append('file', image);
    } else {
      let theImage = image.split('/').reverse()[0];
      payload.append('file', theImage);
    }
    images.map((item) => {
      payload.append('files',item);
    })
    setLoading(true)
    try {
      let response = await axios.post(url, payload, headers);
      if(response.status === 200) {
        doGet()
        toggleForm()
        clearForm()
        setInfo({
          title: 'Success',
          msg: 'Data Successfully Saved',
          bg: 'success',
          show: true
        })
      }
      setLoading(false)
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Data Failed to Save',
        bg: 'danger',
        show: true
      })
      setLoading(false)
    }
  }
  const doCreate = async () => {
    setIsUpdate(false)
    clearForm()
    toggleForm()
  }
  const doUpdate = async (item) => {
    let updateData = {...item}
    let theTag = []
    let tagItem = []
    if(updateData.tag){
      theTag = updateData.tag.split(',')
      theTag.map((item) => {
        tagItem.push({value: item, label: item})
      })
    }
    setId(updateData.id)
    setImage(updateData.image.props.src)
    setImages(updateData.images)
    setTitle(updateData.title)
    setContent(updateData.content)
    setCategory([])
    setTags(tagItem)
    setIsUpdate(true)
    toggleForm()
  }
  const doDelete = async (prompt,item) => {
    if(prompt){
      setDeleteItem(item)
      toggleDelete()
    } else {
      try {
        let res = await axios.get(`/News/${deleteItem.id}`)
        if(res.status === 200){
          doGet()
          setInfo({
            title: 'Success',
            msg: 'Data Successfully Deleted',
            bg: 'success',
            show: true
          })
        } else {
          setInfo({
            title: 'Error',
            msg: 'Data Failed to Delete',
            bg: 'danger',
            show: true
          })
        }
      } catch (error) {
        setInfo({
          title: 'Error',
          msg: 'Internal server error',
          bg: 'danger',
          show: true
        })
      }
      toggleDelete()
    }
  }
  const clearForm = () => {
    setImage('');
    setImages([]);
    setTitle('');
    setContent('');
    setCategory('');
    setTags('');
  }
  useEffect(() => {
    doGet()
  }, [])
  return (
      <>
        <Breadcumb title="News" links={breadCumbLinks}/>
        <div>
          <div className='mina-table-filter p-3 my-4'>
            <h6>Filter</h6>
            <div className='row g-2 w-100 align-items-end'>
              <div className='col-4'>
                <label className="form-label">Kategori</label>
                <select type="email" className="form-control">
                  <option>Select</option>
                </select>
              </div>
              <div className='col-4'>
                <label className="form-label">Tag</label>
                <select type="email" className="form-control">
                  <option>Select</option>
                </select>
              </div>
              <div className='col-4 d-inline-flex justify-content-end'>
                <button className='btn btn-primary' onClick={doCreate} type='button'><i className="bi bi-plus-circle"></i> Add New</button>
              </div>
            </div>
          </div>
          <Table 
            heading={tableHeading} 
            body={tableBody} 
            paging={tablePaging} 
            action={tableAction} 
            onUpdate={(val) => {doUpdate(val)}} 
            onDelete={(val) => {doDelete(true,val)}} 
            onTableChange={(val) => {onTableChange(val)}} 
          />
        </div>
        <Modal show={showForm} onHide={toggleForm} centered size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Form News</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row g-2'>
              <div className='col-12 col-sm-12'>
                <label className="form-label">Image</label>
                <ImageUpload onChange={(val) => {setImage(val)}} previewImage={image}/>
              </div>
              <div className='col-12 col-sm-12'>
                <label className="form-label">Images</label>
                <ImageUpload onChange={(val) => {handleImages(val)}} multiple previewImage={images}/>
              </div>
              <div className='col-12 col-sm-12'>
                <label className="form-label">Title</label>
                <input className='form-control' onChange={(e) => {setTitle(e.target.value)}} value={title}/>
              </div>
              <div className='col-12 col-sm-12'>
                <label className="form-label">Content</label>
                <div className='border'>
                  <ReactQuill value={content} onChange={(val) => setContent(val)} />
                </div>
              </div>
              <div className='col-12 col-sm-12'>
                <label className="form-label">Category</label>
                <CreatableSelect
                  isClearable
                  isMulti
                  placeholder="Select Category"
                  options={optCategory}
                  onChange={(val) => {setCategory(val)}}
                  value={category}
                />
              </div>
              <div className='col-12 col-sm-12'>
                <label className="form-label">Tags</label>
                <CreatableSelect
                  isClearable
                  isMulti
                  placeholder='Add Tags'
                  onChange={(val) => {setTags(val)}}
                  value={tags}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{toggleForm(); clearForm() }}>
              Cancel
            </Button>
            <Button variant="primary" onClick={doSave} disabled={loading}>
              {(loading) ? 'Processing...': 'Save'}
            </Button>
          </Modal.Footer>
        </Modal>
        <DeletePrompt show={showDelete} onHide={() => {toggleDelete()}} onDelete={() => {doDelete(false,deleteItem)}}/>
        <ToasterInfo title={info.title} msg={info.msg} bg={info.bg} show={info.show} setShowInfo={(val)=>{
          let infos = {...info}
          infos.show = val
          setInfo(infos)
        }}/>
      </>
  )
}