import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Breadcumb from '../../components/layout/breadcumb';
import Table from '../../components/global/Table';
import ToasterInfo from '../../components/global/ToasterInfo';
import DeletePrompt from '../../components/global/DeletePrompt';
import axios from '../../config/axios';
import Select from 'react-select';
import userStore from '../../stores/userStore';
export default function FileSharing() {
  const [account, setAccount] = useState(userStore.getAccount());
  const [token, setToken] = useState(userStore.getToken());
  const [isUpdate, setIsUpdate] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [id, setId] = useState('');
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('');
  const [filesize, setFilesize] = useState('');
  const [filetype, setFiletype] = useState('');
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [category, setCategory] = useState('');
  const [group, setGroup] = useState('');
  const [optGroup, setOptGroup] = useState([]);
  const [optCategory, setOptCategory] = useState([
    {
      value: 1,
      label: 'Dokumen'
    },
    {
      value: 2,
      label: 'Dokumen General'
    },
    {
      value: 3,
      label: 'Dokumen Spesifik'
    },
    {
      value: 4,
      label: 'Weekly Meeting'
    },
    {
      value: 5,
      label: 'New Project'
    }
  ]);
  const [loading, setLoading] = useState(false);
  const [breadCumbLinks] = useState([
    {
      path: '/administration',
      title: 'Administration'
    },
    {
      path: '/administration/file-sharing',
      title: 'File Sharing'
    }
  ])
  const [tableHeading] = useState([
    {
      key: 'id',
      label: 'No',
      sort: false,
      width: '5%'
    },
    {
      key: 'title',
      label: 'Title',
      sort: true
    },
    {
      key: 'doc_type',
      label: 'Tipe File',
      sort: true
    },
    {
      key: 'doc_size',
      label: 'Ukuran',
      sort: true
    },
    {
      key: 'createdname',
      label: 'Dibuat Oleh',
      sort: true
    },
    {
      key: 'createddate',
      label: 'Dibuat Tanggal',
      sort: true
    },
    {
      key: 'action',
      label: 'Action',
      sort: false
    }
  ])
  const [tableBody, setTableBody] = useState([]);
  const [tablePaging, setTablePaging] = useState({
    currentPage: 1,
    perPage: 10,
    sortBy: 'id',
    sortDesc: true,
    search: '',
    totalRows: 0,
    totalPages: 0,
    perPageOptions: [10, 20, 50, 100]
  })
  const tableAction = ['update','delete']
  const [info, setInfo] = useState({
    title: '',
    msg: '',
    bg: 'Primary',
    show: false
  });
  const onTableChange = (paging) => {
    doGet(paging)
  }
  const toggleForm = () => { setShowForm(!showForm) }
  const toggleDelete = () => { setShowDelete(!showDelete) }
  const handleFile = (e) => {
    let file = e.target.files[0]
    let size = Math.ceil(file.size/ 1000) + ' KB'
    setFile(file)
    setFilename(file.name)
    setFilesize(size)
    setFiletype(file.type)
  }
  const doGetGroup = async () => {
    try {
      let url = '/Group/list'
      let payload = {
        currentPage: 1,
        perPage: 1000,
        sortBy: 'id',
        sortDesc: true,
        search: ''
      }
      let res = await axios.post(url, payload)
      if(res.status == 200){
        let data = res.data.data
        let opt = data.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setOptGroup(opt)
      }
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Failed to get Role List Data',
        bg: 'danger',
        show: true
      })
    }
  }
  const doGet = async (paging) => {
    try {
      let url = '/Fileshare/list'
      let payload = (paging) ? {...paging} : {...tablePaging}
      let res = await axios.post(url, payload)
      if(res.status == 200){
        let {data,paging} = res.data
        data.map((item) => {
          item.image = <img src={item.image} alt={item.heading} width="100" />
        })
        setTableBody(data)
        let newPaging = {...tablePaging, ...paging}
        setTablePaging(newPaging)
      }
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Failed to get List Data',
        bg: 'danger',
        show: true
      })
    }
  }
  const doSave = async () => {
    setLoading(true)
    let thecategory = []
    let thegroup = []
    let payload = new FormData();
    category.map((item) => { thecategory.push(item.value) })
    group.map((item) => { thegroup.push(item.value) })
    payload.append('title', title)
    payload.append('description', summary)
    payload.append('category', thecategory.toString())
    payload.append('idgroup', thegroup.toString())
    let url = '/Fileshare/create';
    if(isUpdate){
      url = '/Fileshare/update'
      payload.append('updatedby', account.id);
      payload.append('idfileshare', id);
    } else {
      payload.append('createdby', account.id);
    }
    if(typeof(file) == 'object'){
      payload.append('file', file);
    } else {
      let theFile = file.split('/').reverse()[0];
      payload.append('file', theFile);
    }
    let headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    try {
      let response = await axios.post(url, payload, headers);
      if(response.status === 200) {
        doGet()
        toggleForm()
        clearForm()
        setInfo({
          title: 'Success',
          msg: 'Data Successfully Saved',
          bg: 'success',
          show: true
        })
      }
      setLoading(false)
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Data Failed to Save',
        bg: 'danger',
        show: true
      })
      setLoading(false)
    }
  }
  const doCreate = () => {
    setIsUpdate(false)
    clearForm()
    toggleForm()
  }
  const doUpdate = async (item) => {
    let updateData = {...item}
    let groupItem = []
    let categoryItem = []
    if(updateData.id_group){
      let thegroup = updateData.id_group.split(',')
      thegroup.map((item) => {
        optGroup.map((opt) => {
          if(opt.value == item){
            groupItem.push({
              value: opt.value,
              label: opt.label
            })
          }
        })
      })
    }
    if(updateData.category){
      let thecategory = updateData.category.split(',')
      thecategory.map((item) => {
        optCategory.map((opt) => {
          if(opt.value == item){
            categoryItem.push({
              value: opt.value,
              label: opt.label
            })
          }
        })
      })
    }
    setId(updateData.id)
    setFile(updateData.file)
    setFilename(updateData.filename)
    setFilesize(updateData.doc_size)
    setFiletype(updateData.doc_type)
    setTitle(updateData.title)
    setSummary(updateData.description)
    setCategory(categoryItem)
    setGroup(groupItem)
    toggleForm()
  }
  const doDelete = async (prompt,item) => {
    if(prompt){
      setDeleteItem(item)
      toggleDelete()
    } else {
      try {
        let res = await axios.get(`/Fileshare/${deleteItem.id}`)
        if(res.status === 200){
          doGet()
          setInfo({
            title: 'Success',
            msg: 'Data Successfully Deleted',
            bg: 'success',
            show: true
          })
        } else {
          setInfo({
            title: 'Error',
            msg: 'Data Failed to Delete',
            bg: 'danger',
            show: true
          })
        }
      } catch (error) {
        setInfo({
          title: 'Error',
          msg: 'Internal server error',
          bg: 'danger',
          show: true
        })
      }
      toggleDelete()
    }
  }
  const clearForm = () => {
    setId('')
    setFile('')
    setFilename('')
    setFilesize('')
    setFiletype('')
    setTitle('')
    setSummary('')
    setCategory([])
    setGroup([])
  }
  useEffect(() => {
    doGet()
    doGetGroup()
  }, [])
  return (
      <>
        <Breadcumb title="File Sharing" links={breadCumbLinks}/>
        <div>
          <div className='mina-table-filter p-3 my-4'>
            <h6>Filter</h6>
            <div className='row g-2 align-items-end'>
              <div className='col-4'>
                <label className="form-label">Tipe File</label>
                <select type="email" className="form-control">
                  <option>Select</option>
                </select>
              </div>
              <div className='col-4'>
                <label className="form-label">Kategori</label>
                <select type="email" className="form-control">
                  <option>Select</option>
                </select>
              </div>
              <div className='col-4 d-inline-flex justify-content-end'>
                <button className='btn btn-primary' onClick={doCreate} type='button'><i className="bi bi-plus-circle"></i> Add New</button>
              </div>
            </div>
          </div>
          <Table 
            heading={tableHeading} 
            body={tableBody} 
            paging={tablePaging} 
            action={tableAction} 
            onUpdate={(val) => {doUpdate(val)}} 
            onDelete={(val) => {doDelete(true,val)}} 
            onTableChange={(val) => {onTableChange(val)}} 
          />
        </div>
        <Modal show={showForm} onHide={toggleForm} centered size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Form File Sharing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row g-2'>
              <div className='col-12 col-sm-12'>
                <label className="form-label">File</label>
                <input type='file' className='form-control' onChange={(e) => {handleFile(e)}}/>
              </div>
              <div className='col-12 col-sm-4'>
                <label className="form-label">Filename</label>
                <input className='form-control' disabled value={filename}/>
              </div>
              <div className='col-12 col-sm-4'>
                <label className="form-label">File Type</label>
                <input className='form-control' disabled value={filetype}/>
              </div>
              <div className='col-12 col-sm-4'>
                <label className="form-label">File Size</label>
                <input className='form-control' disabled value={filesize}/>
              </div>
              <div className='col-12 col-sm-12'>
                <label className="form-label">Title</label>
                <input className='form-control' value={title} onChange={(e) => { setTitle(e.target.value) }}/>
              </div>
              <div className='col-12 col-sm-12'>
                <label className="form-label">Summary</label>
                <textarea className='form-control' value={summary} onChange={(e) => { setSummary(e.target.value) }}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Category</label>
                <Select
                  isClearable
                  isMulti
                  placeholder="Select Category"
                  options={optCategory}
                  onChange={(val) => {setCategory(val)}}
                />
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Group</label>
                <Select
                  isClearable
                  isMulti
                  placeholder="Select Group"
                  options={optGroup}
                  onChange={(val) => {setGroup(val)}}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{toggleForm(); clearForm() }}>
              Cancel
            </Button>
            <Button variant="primary" onClick={doSave} disabled={loading}>
            {(loading) ? 'Processing...': 'Save'}
            </Button>
          </Modal.Footer>
        </Modal>
        <DeletePrompt show={showDelete} onHide={() => {toggleDelete()}} onDelete={() => {doDelete(false,deleteItem)}}/>
        <ToasterInfo title={info.title} msg={info.msg} bg={info.bg} show={info.show} setShowInfo={(val)=>{
          let infos = {...info}
          infos.show = val
          setInfo(infos)
        }}/>
      </>
  )
}