import {
  Routes,
  Route
} from "react-router-dom";
import menu from './menu'
export default function Routing() {
  function renderMenu(theMenu){
    return theMenu.map((item, idx) => {
      return (
        <Route 
          key={idx} 
          path={(item.index) ? false : item.path}
          element={<item.components/>}
          index={(item.index) ? true : false}
        >
          {(item.children) && renderMenu(item.children)}
        </Route>
      )
    })
  }
  return (
    <Routes>
      {renderMenu(menu)}
    </Routes>
  )
}