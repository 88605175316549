import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Breadcumb from '../../../components/layout/breadcumb';
import Table from '../../../components/global/Table';
import ToasterInfo from '../../../components/global/ToasterInfo';
import DeletePrompt from '../../../components/global/DeletePrompt';
import axios from '../../../config/axios';
import Select from 'react-select';
import userStore from '../../../stores/userStore';
export default function Users() {
  const [account, setAccount] = useState(userStore.getAccount());
  const [token, setToken] = useState(userStore.getToken());
  const [isUpdate, setIsUpdate] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [id, setId] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState([]);
  const [loading, setLoading] = useState(false);
  const [optRole, setOptRole] = useState([]);
  const breadCumbLinks = [
    {
      path: '/administration',
      title: 'Administration'
    },
    {
      path: '/administration/settings`',
      title: 'Settings'
    },
    {
      path: '/administration/settings/users`',
      title: 'Users'
    }
  ]
  const [tableHeading] = useState([
    {
      key: 'id',
      label: 'No',
      sort: false,
      width: '5%'
    },
    {
      key: 'name',
      label: 'Name',
      sort: true
    },
    {
      key: 'username',
      label: 'Username',
      sort: true
    },
    {
      key: 'email',
      label: 'Email',
      sort: true
    },
    {
      key: 'role',
      label: 'Role',
      sort: true
    },
    {
      key: 'action',
      label: 'Action',
      sort: false
    }
  ])
  const [tableBody, setTableBody] = useState([]);
  const [tablePaging, setTablePaging] = useState({
    currentPage: 1,
    perPage: 10,
    sortBy: 'id',
    sortDesc: true,
    search: '',
    totalRows: 0,
    totalPages: 0,
    perPageOptions: [10, 20, 50, 100]
  })
  const tableAction = ['update','delete']
  const [info, setInfo] = useState({
    title: '',
    msg: '',
    bg: 'Primary',
    show: false
  });
  const onTableChange = (paging) => {
    doGet(paging)
  }
  const toggleForm = () => { setShowForm(!showForm) }
  const toggleDelete = () => { setShowDelete(!showDelete) }
  const doGetRole = async () => {
    try {
      let url = '/Group/list'
      let payload = {
        currentPage: 1,
        perPage: 1000,
        sortBy: 'id',
        sortDesc: true,
        search: ''
      }
      let res = await axios.post(url, payload)
      if(res.status == 200){
        let data = res.data.data
        let opt = data.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setOptRole(opt)
      }
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Failed to get Role List Data',
        bg: 'danger',
        show: true
      })
    }
  }
  const doGet = async (paging) => {
    try {
      let url = '/user/list'
      let payload = (paging) ? {...paging} : {...tablePaging}
      let res = await axios.post(url, payload)
      if(res.status == 200){
        // let {data,paging} = res.data
        setTableBody(res.data)
        // let newPaging = {...tablePaging, ...paging}
        // setTablePaging(newPaging)
      }
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Failed to get List Data',
        bg: 'danger',
        show: true
      })
    }
  }
  const doSave = async () => {
    setLoading(true)
    let roles = []
    role.map(item => {
      roles.push(item.value)
    })
    let payload = new FormData();
    payload.append('username', username);
    payload.append('password', password);
    payload.append('email', email);
    payload.append('name', name);
    payload.append('phone', phone);
    payload.append('role', roles);
    let url = '/user/create';
    if(isUpdate){
      url = '/user/update'
      payload.append('updatedby', account.id);
      payload.append('idusr', id);
    } else {
      payload.append('createdby', account.id);
    }
    let headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    try {
      let response = await axios.post(url, payload, headers);
      if(response.status === 200) {
        doGet()
        toggleForm()
        clearForm()
        setInfo({
          title: 'Success',
          msg: 'Data Successfully Saved',
          bg: 'success',
          show: true
        })
      }
      setLoading(false)
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Data Failed to Save',
        bg: 'danger',
        show: true
      })
      setLoading(false)
    }
  }
  const doCreate = () => {
    setIsUpdate(false)
    toggleForm()
  }
  const doUpdate = async (item) => {
    let updateData = {...item}
    let theRole = []
    let roleItem = []
    if(updateData.role){
      theRole = updateData.role.split(',')
      theRole.map((item) => {
        optRole.find(data => {
          if(data.value == item){
            roleItem.push(data)
          }
        })
      })
    }
    setId(updateData.id)
    setUsername(updateData.username)
    setPassword('')
    setEmail(updateData.email)
    setName(updateData.name)
    setPhone(updateData.phone)
    setRole(roleItem)
    setIsUpdate(true)
    toggleForm()
  }
  const doDelete = async (prompt,item) => {
    if(prompt){
      setDeleteItem(item)
      toggleDelete()
    } else {
      try {
        let res = await axios.get(`/user/${deleteItem.id}`)
        if(res.status === 200){
          doGet()
          setInfo({
            title: 'Success',
            msg: 'Data Successfully Deleted',
            bg: 'success',
            show: true
          })
        } else {
          setInfo({
            title: 'Error',
            msg: 'Data Failed to Delete',
            bg: 'danger',
            show: true
          })
        }
      } catch (error) {
        setInfo({
          title: 'Error',
          msg: 'Internal server error',
          bg: 'danger',
          show: true
        })
      }
      toggleDelete()
    }
  }
  const clearForm = () => {
    setUsername('')
    setPassword('')
    setEmail('')
    setName('')
    setPhone('')
    setRole([])
  }
  useEffect(() => {
    doGet()
    doGetRole()
  }, [])
  return (
      <>
        <Breadcumb title="Users" links={breadCumbLinks}/>
        <div>
          <div className='mina-table-filter p-3 my-4'>
            <h6>Filter</h6>
            <div className='row g-2 align-items-end'>
              <div className='col-4'>
                <label className="form-label">Role</label>
                <select type="email" className="form-control">
                  <option>Select</option>
                </select>
              </div>
              <div className='col-4'>
                <label className="form-label">Status</label>
                <select type="email" className="form-control">
                  <option>Select</option>
                </select>
              </div>
              <div className='col-4 d-inline-flex justify-content-end'>
                <button className='btn btn-primary'><i className="bi bi-box-arrow-in-right"></i> Import</button>
                <button className='btn btn-primary ms-1' onClick={doCreate} type='button'><i className="bi bi-plus-circle"></i> Add New</button>
              </div>
            </div>
          </div>
          <Table 
            heading={tableHeading} 
            body={tableBody} 
            paging={tablePaging} 
            action={tableAction} 
            onUpdate={(val) => {doUpdate(val)}} 
            onDelete={(val) => {doDelete(true,val)}} 
            onTableChange={(val) => {onTableChange(val)}} 
          />
        </div>
        <Modal show={showForm} onHide={toggleForm} centered size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Form User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row g-2'>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Name</label>
                <input className='form-control' onChange={(e)=>{setName(e.target.value)}} value={name}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Username</label>
                <input className='form-control' onChange={(e)=>{setUsername(e.target.value)}} value={username}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Password</label>
                <input className='form-control' type='password' onChange={(e)=>{setPassword(e.target.value)}} value={password}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Email</label>
                <input className='form-control' type='email' onChange={(e)=>{setEmail(e.target.value)}} value={email}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Phone</label>
                <input className='form-control' onChange={(e)=>{setPhone(e.target.value)}} value={phone}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Role</label>
                <Select
                  isClearable
                  isMulti
                  placeholder="Select Role"
                  options={optRole}
                  onChange={(val) => {setRole(val)}}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{toggleForm(); clearForm() }}>
              Cancel
            </Button>
            <Button variant="primary" onClick={doSave} disabled={loading}>
            {(loading) ? 'Processing...': 'Save'}
            </Button>
          </Modal.Footer>
        </Modal>
        <DeletePrompt show={showDelete} onHide={() => {toggleDelete()}} onDelete={() => {doDelete(false,deleteItem)}}/>
        <ToasterInfo title={info.title} msg={info.msg} bg={info.bg} show={info.show} setShowInfo={(val)=>{
          let infos = {...info}
          infos.show = val
          setInfo(infos)
        }}/>
      </>
  )
}