import { Link } from 'react-router-dom';
export default function FileSharingSidebar(){
  const category = ['HRD','HRM','Karyawan','Gaji','Cuti']
  const tags = [
    {
      label: 'PDF',
    },
    {
      label: 'Document',
    },
    {
      label: 'Archive',
    },
    {
      label: 'Audio',
    },
    {
      label: 'Illustrator',
    },
    {
      label: 'Persentation',
    },
    {
      label: 'Photoshop',
    },
    {
      label: 'Video',
    },
    {
      label: 'sheet',
    }
  ]
  return (
    <div className="mina-menu-left-filesharing mt-5 pt-3">
      <h6>Kategori</h6>
      <ul className="mina-menu-list">
        {category.map((item, index) => {
          return (
            <li key={index}>
              <Link to="#">{item}</Link>
            </li>
          )
        })}
      </ul>
      <h6>Tags</h6>
      <ul className="mina-menu-tags">
        {tags.map((item, index) => {
          return (
            <li key={index}>
              <Link to="#" className='d-flex align-items-center'>
                {item.label}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}