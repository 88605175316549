import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Breadcumb from '../../components/layout/breadcumb';
import Table from '../../components/global/Table';
import ImageUpload from '../../components/global/ImageUpload';
import ToasterInfo from '../../components/global/ToasterInfo';
import DeletePrompt from '../../components/global/DeletePrompt';
import axios from '../../config/axios';
import { BannerServices } from '../../services';
import DatePicker from 'react-date-picker';
import userStore from '../../stores/userStore';
export default function Banner() {
  const [account, setAccount] = useState(userStore.getAccount());
  const [token, setToken] = useState(userStore.getToken());
  const [isUpdate, setIsUpdate] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [id, setId] = useState('');
  const [image, setImage] = useState('');
  const [heading, setHeading] = useState('');
  const [subheading, setSubheading] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonLink, setButtonLink] = useState('');
  const [expiredDate, setExpiredDate] = useState();
  const [status, setStatus] = useState('');
  const [order, setOrder] = useState('');
  const [loading, setLoading] = useState(false);
  const optOrder = [
    {
      value: 1,
      label: 1
    }
  ]
  const [breadCumbLinks] = useState([
    {
      path: '/administration',
      title: 'Administration'
    },
    {
      path: '/administration/banner',
      title: 'Banner'
    }
  ])
  const [tableHeading] = useState([
    {
      key: 'id',
      label: 'No',
      sort: false,
      width: '5%'
    },
    {
      key: 'image',
      label: 'Image',
      sort: false
    },
    {
      key: 'heading',
      label: 'Heading',
      sort: true
    },
    {
      key: 'expireddate',
      label: 'Expired date',
      sort: true
    },
    {
      key: 'createdname',
      label: 'Dibuat Oleh',
      sort: true
    },
    {
      key: 'createddate',
      label: 'Dibuat Tanggal',
      sort: true
    },
    {
      key: 'action',
      label: 'Action',
      sort: false,
      width: '10%'
    }
  ])
  const [tableBody, setTableBody] = useState([]);
  const [tablePaging, setTablePaging] = useState({
    currentPage: 1,
    perPage: 10,
    sortBy: 'id',
    sortDesc: true,
    search: '',
    totalRows: 0,
    totalPages: 0,
    perPageOptions: [10, 20, 50, 100]
  })
  const tableAction = [
    'update', 'delete'
  ]
  const [info, setInfo] = useState({
    title: '',
    msg: '',
    bg: 'Primary',
    show: false
  });
  const onTableChange = (paging) => {
    doGet(paging)
  }
  const toggleForm = () => { 
    setShowForm(!showForm)
  }
  const toggleDelete = () => {
    setShowDelete(!showDelete)
  }
  const doGet = async (paging) => {
    try {
      let payload = (paging) ? {...paging} : {...tablePaging}
      let res = await BannerServices.list(payload)
      if(res.status === 200){
        let {data,paging} = res.data
        data.map((item) => {
          item.image = <img src={item.image} alt={item.heading} width="100" />
          let dateObj = new Date(item.expired_date);
          let monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          let month = dateObj.getMonth()
          let day = dateObj.getDate()
          let year = dateObj.getFullYear()
          item.expireddate = day + "/" + monthName[month] + "/" + year;
        })
        setTableBody(data)
        let newPaging = {...tablePaging, ...paging}
        setTablePaging(newPaging)
      }
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Failed to get List Data',
        bg: 'danger',
        show: true
      })
    }
  }
  const doCreate = () => {
    setIsUpdate(false)
    clearForm()
    toggleForm()
  }
  const doUpdate = async (item) => {
    let updateData = {...item}
    setId(updateData.id)
    setImage(updateData.image.props.src)
    setHeading(updateData.heading)
    setSubheading(updateData.subheading)
    setButtonText(updateData.btn_text)
    setButtonLink(updateData.btn_link)
    setStatus(updateData.status)
    setOrder(updateData.ordering)
    setExpiredDate(new Date(updateData.expired_date))
    toggleForm()
    setIsUpdate(true)
  }
  const doSave = async () => {
    setLoading(true)
    let payload = new FormData();
    payload.append('heading', heading);
    payload.append('subheading', subheading);
    payload.append('btn_text', buttonText);
    payload.append('btn_link', buttonLink);
    payload.append('status', 'Active');
    payload.append('ordering', 1);
    payload.append('expired_date', expiredDate);
    let url = '/Banner/create';
    if(isUpdate){
      url = '/Banner/update'
      payload.append('idbanner', id);
      payload.append('updatedby', account.id);
    } else {
      payload.append('createdby', account.id);
    }
    if(typeof(image) == 'object'){
      payload.append('file', image);
    } else {
      let theImage = image.split('/').reverse()[0];
      payload.append('file', theImage);
    }
    let headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    try {
      let response = await axios.post(url, payload, headers);
      if(response.status === 200) {
        doGet()
        toggleForm()
        clearForm()
        setInfo({
          title: 'Success',
          msg: 'Data Successfully Saved',
          bg: 'success',
          show: true
        })
      }
      setLoading(false)
    } catch (error) {
      setInfo({
        title: 'Error',
        msg: 'Data Failed to Save',
        bg: 'danger',
        show: true
      })
      setLoading(false)
    }
  }
  const doDelete = async (prompt,item) => {
    if(prompt){
      setDeleteItem(item)
      toggleDelete()
    } else {
      try {
        let res = await axios.get(`/Banner/${deleteItem.id}`)
        if(res.status === 200){
          doGet()
          setInfo({
            title: 'Success',
            msg: 'Data Successfully Deleted',
            bg: 'success',
            show: true
          })
        } else {
          setInfo({
            title: 'Error',
            msg: 'Data Failed to Delete',
            bg: 'danger',
            show: true
          })
        }
      } catch (error) {
        setInfo({
          title: 'Error',
          msg: 'Internal server error',
          bg: 'danger',
          show: true
        })
      }
      toggleDelete()
    }
  }
  const clearForm = () => {
    setImage('');
    setHeading('');
    setSubheading('');
    setButtonText('');
    setButtonLink('');
    setStatus('');
    setOrder('');
    setExpiredDate('')
  }
  useEffect(() => {
    doGet()
  }, [])
  return (
      <>
        <Breadcumb title="Banner" links={breadCumbLinks}/>
        <div>
          <div className='mina-table-navbar p-3 my-4 d-flex justify-content-end'>
            <div className='row g-2 align-items-center'>
              <div className='col-auto'>
                <button className='btn btn-primary' onClick={doCreate} type='button'><i className="bi bi-plus-circle"></i> Add New</button>
              </div>
            </div>
          </div>
          <Table 
            heading={tableHeading} 
            body={tableBody} 
            paging={tablePaging} 
            action={tableAction} 
            onUpdate={(val) => {doUpdate(val)}} 
            onDelete={(val) => {doDelete(true,val)}} 
            onTableChange={(val) => {onTableChange(val)}} 
          />
        </div>
        <Modal show={showForm} onHide={toggleForm} centered size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>Form Banner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row g-2'>
              <div className='col-12 col-sm-12'>
                <label className="form-label">Image</label>
                <ImageUpload onChange={(val) => {setImage(val)}} previewImage={image}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Heading</label>
                <input className='form-control' value={heading} onChange={e => setHeading(e.target.value)}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Sub Heading</label>
                <input className='form-control' value={subheading} onChange={e => setSubheading(e.target.value)}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Button Text</label>
                <input className='form-control' value={buttonText} onChange={e => setButtonText(e.target.value)}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Button Link</label>
                <input className='form-control' value={buttonLink} onChange={e => setButtonLink(e.target.value)}/>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Expired Date</label>
                <DatePicker onChange={val => setExpiredDate(val)} value={expiredDate} className="form-control" format="dd/MMM/yyyy"/>
              </div>
              {/* <div className='col-12 col-sm-6'>
                <label className="form-label">Order</label>
                <select className='form-control' value={order} onChange={e => setOrder(e.target.value)}>
                  <option>Select</option>
                  {optOrder.map(({ value, label }, index) => <option value={value} key={index}>{label}</option>)}
                </select>
              </div>
              <div className='col-12 col-sm-6'>
                <label className="form-label">Status</label>
                <select className='form-control' value={status} onChange={e => setStatus(e.target.value)}>
                  <option>Select</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{toggleForm(); clearForm() }}>
              Cancel
            </Button>
            <Button variant="primary" onClick={doSave} disabled={loading}>
            {(loading) ? 'Processing...': 'Save'}
            </Button>
          </Modal.Footer>
        </Modal>
        <DeletePrompt show={showDelete} onHide={() => {toggleDelete()}} onDelete={() => {doDelete(false,deleteItem)}}/>
        <ToasterInfo title={info.title} msg={info.msg} bg={info.bg} show={info.show} setShowInfo={(val)=>{
          let infos = {...info}
          infos.show = val
          setInfo(infos)
        }}/>
      </>
  )
}