import axios from '../config/axios'
const list = async (payload) => {
  let url = '/Banner/list'
  const req = await axios.post(url,payload);
  return req
};
const create = async (payload) => {
  let url = '/Banner/create'
  const req = await axios.post(url,payload);
  return req
};
const update = async (payload) => {
  let url = '/Banner/update'
  const req = await axios.post(url,payload);
  return req
};
const remove = async (id) => {
  let url = '/Banner/'+id
  const req = await axios.get(url);
  return req
};
export default {
  list,
  create,
  update,
  remove
};